// @flow
import axios from "axios";
import type { AxiosInstance } from "axios";
import tvgConf from "@tvg/conf";
import type { TvgConf } from "@tvg/conf/src/types";
import { get } from "lodash";
import { v4 as generateUUID } from "uuid";
import {
  getAuthTokenCookie,
  getSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";
import { parseWithTarget } from "@fdr/utils/generalUtils";
import { serializePromos } from "@fdr/utils/PromoUtils";
import type { CPPPromo } from "@fdr-mar/promos-types/Promos";

export type Options = { isQuicklink?: boolean };

export const CPP_SOURCE = "CPP";

const PROMO_SECTIONS = [
  "content_title",
  "content_text",
  "info_section",
  "optin_button",
  "legal_content"
];

const CPP_CONTAINERS = {
  quicklinks: "RACING_CAROUSEL",
  promos: "RACING"
};

const createInternalPath = (url: string) => {
  if (typeof window !== "undefined") {
    if (url.includes(window.location.hostname)) {
      const { pathname, search, hash } = new URL(url);
      return pathname + search + hash;
    }
  }

  return url;
};

export class CPPService {
  constructor() {
    this.tvgConf = tvgConf();
    this.servicePromos = [];
    this.baseAPI = axios.create({
      baseURL: `${tvgConf().config(
        "externalServices.cpp"
      )}/api/customisedPromotions/`,
      withCredentials: true,
      headers: {
        "X-Authentication": getAuthTokenCookie(),
        "X-Cpp-Region": getSportsbookRegionCookie() || "NY"
      }
    });
  }

  tvgConf: TvgConf;

  servicePromos: CPPPromo[];

  baseAPI: AxiosInstance;

  fetchPromos({ isQuicklink = false }: Options = {}) {
    return this.baseAPI({
      method: "POST",
      url: "/retrieveStructured",
      data: {
        brand: "FANDUEL",
        containers: [
          {
            id: isQuicklink ? CPP_CONTAINERS.quicklinks : CPP_CONTAINERS.promos
          }
        ],
        channel: "desktop"
      }
    });
  }

  setServicePromos(promos: CPPPromo[]) {
    this.servicePromos = promos;
  }

  hasPromoSlug(slug: string) {
    return this.servicePromos.some(({ promoCode }) => promoCode === slug);
  }

  async optIn(promotionCode: string) {
    const { data: result } = await this.baseAPI({
      method: "POST",
      url: "/optIn",
      data: {
        promotionCode,
        attemptId: Date.now().toString()
      },
      headers: {
        "X-Authentication": getAuthTokenCookie()
      }
    });

    if (result.success && result.promotion) {
      const updatedPromos = [...this.servicePromos];
      const currentPromoIndex = updatedPromos.findIndex(
        ({ promoCode }) => promoCode === promotionCode
      );
      updatedPromos[currentPromoIndex] = result.promotion;
      this.setServicePromos(updatedPromos);
    }

    return result;
  }

  getMappedContentForPromo(
    slug: string,
    showHowToPlaySection?: boolean = false
  ) {
    const promo = this.servicePromos.find(
      ({ promoCode }) => promoCode === slug
    );

    if (!promo)
      return {
        body: [],
        component: "page",
        _uid: "12345",
        promo_type: {
          _uid: "67890",
          promo_id: 0,
          segment: "all",
          promoCode: "",
          enablePromoCode: false
        },
        seo_meta_data: {
          _uid: "12345",
          title: "",
          description: ""
        }
      };

    const promoName = get(promo, "name", "");
    const promoDescription = get(promo, "description", "");
    const bannerImgUrl = get(
      promo.images
        .sort((a, b) => b.width - a.width)
        .find(({ tag }) => tag === "banner"),
      "url",
      ""
    );

    const bannerComponent = {
      _uid: generateUUID(),
      categoryTitle: get(promo, "title", ""),
      component: "banner",
      description: "",
      mobilePictureUrl: bannerImgUrl,
      pictureAlt: promoName,
      pictureUrl: bannerImgUrl,
      title: promoName,
      config: {}
    };

    const borderBottomSection = () => ({
      _uid: generateUUID(),
      color: "#e9eff5",
      component: "border_bottom"
    });

    const contentComponents = {
      _uid: generateUUID(),
      component: "content_section",
      bloks: PROMO_SECTIONS.reduce<Array<any>>((accum, component) => {
        switch (component) {
          case "content_title":
            return [...accum, { _uid: generateUUID(), component, header: "" }];
          case "content_text":
            return [
              ...accum,
              {
                _uid: generateUUID(),
                component,
                markdown: promoDescription
              },
              borderBottomSection()
            ];
          case "info_section": {
            if (!showHowToPlaySection) return accum;

            const steps = get(promo, "customerPromotionState.steps", []);

            if (!steps.length) return accum;

            return [
              ...accum,
              {
                _uid: generateUUID(),
                component,
                header: "How to Play",
                steps: steps.map((step, index) => ({
                  component: "info_step",
                  iconName: "",
                  stepDescription: get(step, "action", ""),
                  stepNumber: `${index + 1}`
                }))
              },
              borderBottomSection()
            ];
          }
          case "legal_content":
            return [
              ...accum,
              {
                _uid: generateUUID(),
                component,
                legalItem: parseWithTarget(
                  get(promo, "termsAndConditions.full", "")
                )
              }
            ];
          case "optin_button": {
            const callToAction = get(promo, "callToAction", {});
            const callToActionText = get(callToAction, "text", "");
            const callToActionUrl = get(callToAction, "url", "");
            const customerPromotionState = get(
              promo,
              "customerPromotionState",
              {}
            );
            const forOptin =
              get(customerPromotionState, "eligibility.canOptIn", false) &&
              get(customerPromotionState, "eligibility.canConsent", false) &&
              get(customerPromotionState, "eligibility.eligible", false) &&
              get(callToAction, "action", "") === "OPT_IN";

            const url = createInternalPath(callToActionUrl);

            return [
              ...accum,
              {
                _uid: generateUUID(),
                component,
                ctaLabel: callToActionText,
                forOptin,
                linkLabel: callToActionText,
                path: {
                  id: get(customerPromotionState, "reward.id", ""),
                  url,
                  linktype: "url",
                  fieldtype: "multilink",
                  cached_url: url
                }
              }
            ];
          }

          default:
            return accum;
        }
      }, [])
    };

    const promoCode = get(promo, "promoCode", "");
    const isOptedIn =
      get(promo, "customerPromotionState.optInState") === "ONGOING" &&
      get(promo, "customerPromotionState.hasAccepted", false);

    return {
      _uid: generateUUID(),
      body: [bannerComponent, contentComponents],
      component: "page",
      isOptedIn,
      promo_type: {
        promoId: promoCode,
        _uid: generateUUID(),
        promo_id: promoCode,
        segment: get(promo, "segment", "all"),
        promoCode,
        enablePromoCode: false,
        source: CPP_SOURCE
      },
      seo_meta_data: {
        description: promoDescription,
        title: promoName,
        _uid: generateUUID()
      }
    };
  }

  async getPromos({ isQuicklink = false }: Options = {}) {
    const { data } = await this.fetchPromos({ isQuicklink });
    const racingPromos = data.promoPlacements.find(
      ({ placementId }) =>
        placementId ===
        (isQuicklink ? CPP_CONTAINERS.quicklinks : CPP_CONTAINERS.promos)
    );
    this.setServicePromos(racingPromos.promotions);

    return racingPromos.promotions.length
      ? serializePromos(racingPromos.promotions, { isQuicklink })
      : [];
  }
}
